import React, { useContext, useEffect } from 'react'

// フォームの表示
import { useShortAnswerInput } from 'react-google-forms-hooks'


function ContactShortInput(props) {
  const id = props.id;
  const dispLabel = props.label;
  const placeholder = props.placeholder;
  const setInputValue = props.setInputValue;
  const isEmail = props.isEmail;

  const { register, label } = useShortAnswerInput(id);


  return (
    <div className='contact-input-wrap'>
      <p className='contact-input-label'>{dispLabel}</p>
      <input
        {...register()}
        type={isEmail? 'email' : 'text'}
        className='contact-input'
        placeholder={placeholder}
        onChange={e => {setInputValue(e.target.value)}}
      />
    </div>
  )
}

export default ContactShortInput;