import React, { useEffect, useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';

import '../../../../style/css/rule.css'
import { routeContact } from '../../../../style/css/CmnStyle';
import { hpContext } from '../../HPContext'


// プライバシーポリシー
function PrivacyPolicy() {
  const { t } = useTranslation();
  const ReactGA = useContext(hpContext).ReactGA;

  const scrollHeadHeight = useContext(hpContext).scrollHeadHeight;
  const isWide = useContext(hpContext).isWide;

  useEffect(() => {
    // ログ：ページ
    ReactGA.event("pagePrivacyPolicy");
  }, [])


  return (
    <View style={{marginTop: scrollHeadHeight}}>

      <div className={isWide? 'wide-rule-wrap' : 'rule-wrap'}>

        {/* プライバシーポリシー */}
        <h1 className='rule-title'>{t('privacy.title')}</h1>

        {/* プライバシーポリシー */}
        <div className='article'>
          <h2 className='article-title'>{t('privacy.title')}</h2>
          <p className='article-content'>{t('privacy.premise')}</p>
        </div>

        <div className='article'>
          {/* 1. 個人情報 */}
          <h2 className='article-title'>{t('privacy.a1.title')}</h2>
          <div className='article-content'>
            <ul className='article-ul'>
              <li>{t('privacy.a1.l1')}</li>
              <li>{t('privacy.a1.l2')}</li>
            </ul>
          </div>
        </div>

        <div className='article'>
          {/* 2. 個人情報の収集方法 */}
          <h2 className='article-title'>{t('privacy.a2.title')}</h2>
          <div className='article-content'>
            <p>{t('privacy.a2.premise')}</p>
            <ul className='article-ul'>
              <li>{t('privacy.a2.l1')}</li>
              <li>{t('privacy.a2.l2')}</li>
              <li>{t('privacy.a2.l3')}</li>
              <li>{t('privacy.a2.l4')}</li>
              <li>{t('privacy.a2.l5')}</li>
              <li>{t('privacy.a2.l6')}</li>
            </ul>
          </div>
        </div>

        <div className='article'>
          {/* 3. 利用目的 */}
          <h2 className='article-title'>{t('privacy.a3.title')}</h2>
          <div className='article-content'>
            <p>{t('privacy.a3.premise')}</p>
            <ul className='article-ul'>
              <li>{t('privacy.a3.l1')}</li>
              <li>{t('privacy.a3.l2')}</li>
              <li>{t('privacy.a3.l3')}</li>
              <li>{t('privacy.a3.l4')}</li>
              <li>{t('privacy.a3.l5')}</li>
              <li>{t('privacy.a3.l6')}</li>
              <li>{t('privacy.a3.l7')}</li>
              <li>{t('privacy.a3.l8')}</li>
              <li>{t('privacy.a3.l9')}</li>
              <li>{t('privacy.a3.l10')}</li>
              <li>{t('privacy.a3.l11')}</li>
              <li>{t('privacy.a3.l12')}</li>
              <li>{t('privacy.a3.l13')}</li>
              <li>{t('privacy.a3.l14')}</li>
            </ul>
          </div>
        </div>

        <div className='article'>
          {/* 4. 第三者提供 */}
          <h2 className='article-title'>{t('privacy.a4.title')}</h2>
          <div className='article-content'>
            <p>{t('privacy.a4.premise')}</p>
            <ul className='article-ul'>
              <li>{t('privacy.a4.l1')}</li>
              <li>{t('privacy.a4.l2')}</li>
              <li>{t('privacy.a4.l3')}</li>
              <li>{t('privacy.a4.l4')}</li>
              <li>{t('privacy.a4.l5')}</li>
            </ul>
          </div>
        </div>

        <div className='article'>
          {/* 5. 安全管理措置 */}
          <h2 className='article-title'>{t('privacy.a5.title')}</h2>
          <div className='article-content'>
            <ul className='article-ul'>
              <li>{t('privacy.a5.l1')}</li>
              <li>{t('privacy.a5.l2')}</li>
            </ul>
          </div>
        </div>

        <div className='article'>
          {/* 6. 委託先の監督 */}
          <h2 className='article-title'>{t('privacy.a6.title')}</h2>
          <div className='article-content'>
            <p>{t('privacy.a6.content')}</p>
          </div>
        </div>

        <div className='article'>
          {/* 7. 個人情報の訂正・削除 */}
          <h2 className='article-title'>{t('privacy.a7.title')}</h2>
          <div className='article-content'>
            <ul className='article-ul'>
              <li>{t('privacy.a7.l1')}</li>
              <li>{t('privacy.a7.l2')}</li>
            </ul>
          </div>
        </div>

        <div className='article'>
          {/* 8. 取得した情報の破棄 */}
          <h2 className='article-title'>{t('privacy.a8.title')}</h2>
          <div className='article-content'>
            <p>{t('privacy.a8.content')}</p>
          </div>
        </div>

        <div className='article'>
          {/* 9. 免責について */}
          <h2 className='article-title'>{t('privacy.a9.title')}</h2>
          <div className='article-content'>
            <p>{t('privacy.a9.premise')}</p>
            <ul className='article-ul'>
              <li>{t('privacy.a9.l1')}</li>
              <li>{t('privacy.a9.l2')}</li>
              <li>{t('privacy.a9.l3')}</li>
            </ul>
          </div>
        </div>

        <div className='article'>
          {/* 10. トラッキング */}
          <h2 className='article-title'>{t('privacy.a10.title')}</h2>
          <div className='article-content'>
            <p>{t('privacy.a10.content')}</p>
          </div>
        </div>

        <div className='article'>
          {/* 11. 当ポリシーが適用される範囲 */}
          <h2 className='article-title'>{t('privacy.a11.title')}</h2>
          <div className='article-content'>
            <ul className='article-ul'>
              <li>{t('privacy.a11.l1')}</li>
              <li>{t('privacy.a11.l2')}</li>
            </ul>
          </div>
        </div>

        <div className='article'>
          {/* 12. プライバシーポリシーの改定 */}
          <h2 className='article-title'>{t('privacy.a12.title')}</h2>
          <div className='article-content'>
            <p>{t('privacy.a12.content')}</p>
          </div>
        </div>

        <div className='article'>
          <h2 className='article-title'>{t('privacy.a13.title')}</h2>
          <div className='article-content'>
            <p>
              {t('privacy.a13.before')}
              <a href={routeContact} target='_blank'>{t('privacy.a13.contact')}</a>
              {t('privacy.a13.after')}
            </p>
          </div>
        </div>

        <div className='article history-wrap'>
          {/* 改訂履歴 */}
          <h3 className='history-title'>{t('privacy.history.title')}</h3>
          <ul className='history'>
            <li>{t('privacy.history.v1')}</li>
            <li>{t('privacy.history.v2')}</li>
          </ul>
        </div>

      </div>

    </View>
  );
}

export default PrivacyPolicy;