import React, { useState, useContext, useEffect } from 'react'
import { StyleSheet } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import '../../../../style/css/contact.css'
import { routePrivacyPolicy, routeContactDone } from '../../../../style/css/CmnStyle';
import { hpContext } from '../../HPContext'
import ContactShortInput from './fields/ContactShortInput';
import ContactLongInput from './fields/ContactLongInput';

// フォームの表示
import { GoogleFormProvider, useGoogleForm } from 'react-google-forms-hooks'
import formData from './form.json'


// お問い合わせ
function Contact() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ReactGA = useContext(hpContext).ReactGA;

  const scrollHeadHeight = useContext(hpContext).scrollHeadHeight;
  const isWide = useContext(hpContext).isWide;

  const methods = useGoogleForm({ form:formData })

  // 氏名の入力内容
  const [inputName, setInputName] = useState('');
  // メールアドレスの入力内容
  const [inputMail, setInputMail] = useState('');
  // お問い合わせの入力内容
  const [inputMsg, setInputMsg] = useState('');

  const [checkPrivacy, setCheckPrivacy] = useState(false);

  const [canSend, setCanSend] = useState(false);
  const [isDispValidation, setIsDispValidation] = useState(false);


  useEffect(() => {
    // ログ：ページ
    ReactGA.event("pageContact");
  }, [])


  const onChangePrivacyCheck = (e) => {
    setCheckPrivacy(e.target.checked);
  }


  useEffect(() => {
    checkSendable();
  }, [inputName, inputMail, inputMsg, checkPrivacy])


  // 送信可否の確認
  function checkSendable() {
    // 正規表現を使用してメールアドレスの形式を判定
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(
      inputName && inputName.length>0 &&
      inputMail && emailRegex.test(inputMail) &&
      inputMsg && inputMsg.length>0 &&
      checkPrivacy
    ) {
      setCanSend(true);
    } else {
      setCanSend(false);
    }
  }


  // 送信
  const onSubmit = async (data) => {
    if(canSend) {
      // データ送信
      await methods.submitToGoogleForms(data);
      // ログ：イベント
      ReactGA.event("eventContactSend");
      // 完了画面に遷移
      navigate(routeContactDone);
    } else {
      // バリデーション表示
      setIsDispValidation(true);
    }
  }


  return (
    <div id='contact'style={{marginTop: scrollHeadHeight}}>
      <div className={isWide? 'contact-wrap-wide' : 'contact-wrap'}>

        {/* お問い合わせ/CONTACT */}
        <h1
          className={isWide? 'contact-title-wide' : 'contact-title'}
        >
          {t('contact.pageTitle')}
        </h1>

        <img
          src='/assets/img/app.png'
          className={isWide? 'contact-app-img-wide' : 'contact-app-img'}
        />

        <GoogleFormProvider {...methods}>
          <form className='contact-form' noValidate onSubmit={methods.handleSubmit(onSubmit)}>


            <ContactShortInput
              id='408092660'
              // 氏名 / Full Name
              label={t('contact.name.label')}
              // Your Name
              placeholder={t('contact.name.placeholder')}
              setInputValue={setInputName}
              isEmail={false}
            />

            <ContactShortInput
              id='15157958'
              // メールアドレス / Email
              label={t('contact.mail.label')}
              // Email Address
              placeholder={t('contact.mail.placeholder')}
              setInputValue={setInputMail}
              isEmail={true}
            />

            <ContactLongInput
              id='1119178947'
              // お問い合わせ内容 / Message
              label={t('contact.message.label')}
              // Put message here
              placeholder={t('contact.message.placeholder')}
              setInputValue={setInputMsg}
            />

            <div className='contact-check-privacy'>
              <input type="checkbox" checked={checkPrivacy} onChange={onChangePrivacyCheck} />
              {/*  I agree to the Privacy Policy. */}
              <a href={routePrivacyPolicy} className='contact-check-privacy-link' target="_blank">
                {t('contact.agreePolicy')}
              </a>
            </div>

            <div className='contact-attension-wrap'>
              {/* * 入力したメールアドレスが正しいことをご確認ください */}
              <p className='contact-attension'>{t('contact.attension.line1')}</p>
              {/* * 返信には数日かかる場合があります */}
              <p className='contact-attension'>{t('contact.attension.line2')}</p>
            </div>


            {isDispValidation ?
              <>
                {/* 氏名・メールアドレス・お問い合わせ内容を入力してプライバシーポリシーをご確認ください */}
                <p className='contact-validation'>{t('contact.validation')}</p>
              </>
            :
              <></>
            }

            <div className='contact-send-wrap'>
              {/* Send */}
              <button
                type='submit'
                className={canSend? 'contact-send-btn' : 'contact-send-btn contact-send-btn-disable'}
              >
                {t('contact.btn')}
              </button>
            </div>

          </form>
        </GoogleFormProvider>

      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
  },
})

export default Contact;