import logo from './logo.svg';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';

import Hp from './components/hp/Hp';

function App() {
  return (
    <HelmetProvider>
      <Hp />
    </HelmetProvider>
  );
}

export default App;
