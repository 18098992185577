import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { fontColorWhite, contentWrapColor } from '../../../../../style/css/CmnStyle';
import { hpContext } from '../../../HPContext';

function HpContentFeel(props) {
  const { t } = useTranslation();
  const isWide = useContext(hpContext).isWide;

  const windowDimensions = useContext(hpContext).windowDimensions;
  const windowWidth = windowDimensions.width;

  return (
    <View
      id='feel'
      style={[
        isWide? styles.containerWide : styles.container,
        {paddingHorizontal: isWide? windowWidth*0.08 : windowWidth*0.08},
      ]}
    >
    <View style={isWide? styles.mainWrapWide : styles.mainWrap}>

      {/*
        スケートボードは縛りなく自由にあなたのスタイルで楽しむことができます！{"\n"}
        新しいトリックにチャレンジしたり、メイクしたトリックをよりスタイリッシュにしたりと楽しみ方は無限大です！{"\n"}
        {"\n"}
        でも、スケートボードは難しく一人で練習しているとモチベーションが下がり辞めてしまう人が多くいます。{"\n"}
        {"\n"}
        『Skaters』は上達に便利なツールでもあり、
        年齢・地域に関係なく仲間を作りメイクを一緒に喜び合い、
        スケートライフをより楽しくすることができます！
      */}
      <Text
        style={[
          styles.feelTxt,
          {fontSize: isWide? '1.2em' : '0.9em'}
        ]}
      >
        {t('top.feel')}
      </Text>

    </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: contentWrapColor,
    transform: 'skewY(10deg)',
    paddingTop: 70,
    paddingBottom: 80,
    marginBottom: 150,
  },
  mainWrap: {
    transform: 'skewY(-10deg)',
  },

  containerWide: {
    backgroundColor: contentWrapColor,
    transform: 'skewY(8deg)',
    paddingTop: 80,
    paddingBottom: 130,
    marginBottom: 200,
  },
  mainWrapWide: {
    transform: 'skewY(-8deg)',
    paddingHorizontal: '20%',
  },


  feelTxt: {
    color: fontColorWhite,
    lineHeight: '2em',
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 4,
  },
});

export default HpContentFeel;
