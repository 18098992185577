import React, { useEffect, useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';

import { hpContext } from '../../HPContext'


// 404
function Error404() {
  const { t } = useTranslation();
  const ReactGA = useContext(hpContext).ReactGA;

  const scrollHeadHeight = useContext(hpContext).scrollHeadHeight;


  useEffect(() => {
    // ログ：ページ
    ReactGA.event("pageError404");
  }, [])

  return (
    <View style={[styles.container, {marginTop: scrollHeadHeight}]}>

      {/* 404 NOT FOUND */}
      <Text style={styles.msgTitle}>{t('err.404.title')}</Text>

      {/* お探しのページが見つかりませんでした。 */}
      <Text style={styles.msg}>{t('err.404.discription')}</Text>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 100,
  },
  msgTitle: {
    fontSize: '2em',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  msg: {
    fontSize: '1em',
  },
})

export default Error404;