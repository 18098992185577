// 背景
export const blackColor = '#26211E';
export const contentWrapColor = '#2E2E2E';
// 文字
export const fontColorWhite = '#E6E9EA';


////////// ルート //////////
export const routeTop = '/';
export const routeTopJa = '/ja';
export const routeTopPt = '/pt';
export const routeTopEs = '/es';
export const routeTermOfService = '/term_of_service';
export const routePrivacyPolicy = '/privacy_policy';
export const routeTokutei = '/tokutei';
export const routeContactPrepare = '/contact_prepare';
export const routeContact = '/contact';
export const routeContactDone = '/contact/done';