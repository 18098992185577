import React, { useState, useContext, useEffect } from 'react'
import { Dimensions, Pressable, StyleSheet, Image, Text, View } from 'react-native';
import { useLocation } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  routeTop, routeTopJa, routeTopPt, routeTopEs,
  blackColor, fontColorWhite
} from '../../../style/css/CmnStyle';
import { hpContext } from '../HPContext';
const windowHeight = Dimensions.get('window').height;

function HpHeader(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const scrollHeadHeight = useContext(hpContext).scrollHeadHeight;
  const isWide = useContext(hpContext).isWide;

  // スクロールしたときに表示されるヘッダー
  const [showHeader, setShowHeader] = useState(false);
  // トップページ判定
  const [isRouteTop, setIsRouteTop] = useState(true);

  // 現在表示しているパス
  const location = useLocation();


  useEffect(() => {
    // トップページだけスクロールしてヘッダーを表示させる
    if(
      location.pathname == routeTop ||
      location.pathname == routeTopJa ||
      location.pathname == routeTopPt ||
      location.pathname == routeTopEs
    ) {
      // トップページ
      setIsRouteTop(true);
    } else {
      // トップページ以外
      setIsRouteTop(false);
    }
  }, [location])


  // スクロールして表示されるヘッダー
  useEffect(() => {

    // スクロール時
    const handleScroll = () => {
      // 画面上部からのスクロール位置を取得
      const scrollY = window.scrollY;
      // スクロール位置が画面の半分を超えたらヘッダーを表示する
      if (scrollY > windowHeight/2) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    // スクロールイベントをリスンする
    window.addEventListener("scroll", handleScroll);
    // コンポーネントがアンマウントされるときにイベントリスナーを解除する
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);


  return (
    <>
    {/* トップページ以外、もしくはヘッダー表示フラグがたった場合 */}
    {!isRouteTop || showHeader ?
      <View style={[
        styles.container,
        {paddingHorizontal: isWide? '5vw': '1vw'},
        {height: scrollHeadHeight},
      ]}
      >

        <View style={styles.logoWrap}>
          <Pressable
            accessibilityRole="link"
            onPress={() => {
              navigate(routeTop);
              window.scrollTo({top: 0});
            }}
            style={styles.logoWrap}
          > 
            <Image
              defaultSource={'./assets/img/logo.png'}
              style={[
                {width: scrollHeadHeight},
                {height: scrollHeadHeight},
              ]}
            />
            <Text style={styles.name}>Skaters</Text>
          </Pressable>
        </View>

        <HashLink
          to={routeTop + '#download'}
          style={{textDecoration: 'none'}}
        >
          {/* ダウンロード */}
          <Text style={isWide? styles.downloadWide : styles.download}>{t('download.title')}</Text>
        </HashLink>

      </View>
      :
      <></>
    }
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    flexDirection: 'row',
    width: '100vw',
    backgroundColor: blackColor,
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 5,
  },
  logoWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
  },
  name: {
    color: fontColorWhite,
    fontSize: '1.3em',
    fontWeight: 'bold',
  },
  download: {
    color: fontColorWhite,
    marginHorizontal: 10,
    paddingHorizontal: 15,
    paddingVertical: 5,
    backgroundColor: 'black',
    borderColor: fontColorWhite,
    borderWidth: 1,
    borderRadius: 100,
  },
  downloadWide: {
    color: fontColorWhite,
    fontSize: '1.1em',
    fontWeight: 'bold',
    marginHorizontal: 10,
    paddingHorizontal: 20,
    paddingVertical: 8,
    backgroundColor: 'black',
    borderColor: fontColorWhite,
    borderWidth: 1,
    borderRadius: 100,
  },
});

export default HpHeader;
