import React, { useContext, useEffect } from 'react'

// フォームの表示
import { useLongAnswerInput } from 'react-google-forms-hooks';


function ContactLongInput(props) {
  const id = props.id;
  const dispLabel = props.label;
  const placeholder = props.placeholder;
  const setInputValue = props.setInputValue;

  const { register, label } = useLongAnswerInput(id);


  return (
    <div className='contact-input-wrap contact-input-wrap-last'>
      <p className='contact-input-label'>{dispLabel}</p>
      <textarea
        {...register()}
        className='contact-input contact-textarea'
        placeholder={placeholder}
        onChange={e => {setInputValue(e.target.value)}}
      />
    </div>
  )
}

export default ContactLongInput;