import React, { useEffect, useContext } from 'react'
import { View, Text, Pressable, StyleSheet } from 'react-native';
import TransTextNextLine from '../../../common/language/TransTextNextLine';
import { useTranslation } from 'react-i18next';

import { blackColor, fontColorWhite, routeTop } from '../../../../style/css/CmnStyle';
import { hpContext } from '../../HPContext';


// お問い合わせ完了
function ContactDone() {
  const { t } = useTranslation();
  const ReactGA = useContext(hpContext).ReactGA;

  const scrollHeadHeight = useContext(hpContext).scrollHeadHeight;


  useEffect(() => {
    // ログ：ページ
    ReactGA.event("pageContactDone");
  }, [])


  return (
    <View
      style={[
        styles.container,
        {marginTop: scrollHeadHeight}
      ]}
    >

      {/* お問い合わせ完了 */}
      <Text style={styles.title}>{t('contact.done.title')}</Text>

      {/*
        お問い合わせいただきありがとうございます。{"\n"}
        内容を確認後、お返事させていただきます。
      */}
      <Text>
        <TransTextNextLine text={t('contact.done.discription')} />
      </Text>

      <Pressable accessibilityRole="link" href={routeTop} style={styles.backLink}>
        {/* トップページへ戻る */}
        <Text style={styles.backLinkTxt}>{t('contact.done.back')}</Text>
      </Pressable>

    </View>
  );
} 

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 30,
  },

  title: {
    fontSize: '1.7em',
    fontWeight: 'bold',
    marginBottom: 30,
  },

  backLink: {
    marginTop: 30,
    backgroundColor: blackColor,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 100,
  },
  backLinkTxt: {
    color: fontColorWhite,
    fontWeight: 'bold',
  },
})

export default ContactDone;