import React, { useState, useRef, useEffect, useContext } from 'react'
import { StyleSheet, Image } from 'react-native';
import TransTextNextLine from '../../../../common/language/TransTextNextLine';
import { useTranslation } from 'react-i18next';

//import ReactPlayer from 'react-player/file'
import ReactPlayer from 'react-player/youtube'

import { hpContext } from '../../../HPContext';
import HpTopHeadImg from './img/HpTopHeadImg';


function HpTopHead() {
  const { t } = useTranslation();
  const isWide = useContext(hpContext).isWide;
  const iosLink = useContext(hpContext).iosLink;
  const androidLink = useContext(hpContext).androidLink;

  const onClickIosDownload = useContext(hpContext).onClickIosDownload;
  const onClickAndroidDownload = useContext(hpContext).onClickAndroidDownload;

  // 動画
  const videoRef = useRef(null);
  const videoHeight = '56.25vw';
  const dispUrl = 'https://youtu.be/FbC7eDv8M58';
  const [videoUrl, setVideoUrl] = useState(dispUrl);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isPlayVideo, setIsPlayVideo] = useState(true);


  // 動画の読み込み確認:それまで画像表示
  const handleVideoReady = () => {
    setTimeout(() => {
      setIsVideoLoaded(true);
    }, 500);
  }


  // ブラウザを閉じて開くと再生されない:再生停止検出
  const onPause = () => {
    setIsPlayVideo(false);
  }


  // ブラウザを閉じて再度開いたときに動画が再生されない
  useEffect(() => {
    setInterval(() => {
      // なぜかif(!isPlayVideo)で判定できない
      //if(!isPlayVideo) { setIsPlayVideo(true); }
      setIsPlayVideo(true);
    }, 1000)
  }, []);


  return (
    <header id="header">

      <div className='mainWrap'>

        <div className='contentWrap'>
          {/* 説明 */}
          <div className='titleWrap'>
            <div className='headWrap'>
              <div className='mainHead'>
                <img
                  className='logoImg'
                  src='./assets/img/logo.png'
                  alt='Skaters'
                />
                <h1 className='mainTitle'>Skaters</h1>
              </div>

              {/*
                トリックの瞬間を見逃さない<br/>
                スケーター専用アプリ<br/>
              */}
              <p className='subTitle'>
                <TransTextNextLine text={t('common.catch')} />
              </p>
            </div>
          </div>

          {/* 画像 */}
          <HpTopHeadImg />
        </div>

        {/* ダウンロード */}
        <div className='downloadWrap'>
          <a className='downloadLink' href={iosLink} onClick={onClickIosDownload} rel="noopener" target="_blank">
            <img
              className='download-btn download-btn-apple'
              src={t('download.iosBtnPath')}
              alt='apple-store'
            />
          </a>

          <a className='downloadLink' href={androidLink} onClick={onClickAndroidDownload} rel="noopener" target="_blank">
            <img
              className='download-btn download-btn-google'
              src={t('download.androidBtnPath')}
              alt='google-store'
            />
          </a>
        </div>

      </div>

      {/* 動画 */}
      <div id='video-area-over'></div>
      <div id="video-area">
        {!isVideoLoaded && (
          <Image
            defaultSource={'./assets/img/park/park2.png'}
            resizeMode='cover'
            style={{height: '100%'}}
          />
        )}
        <ReactPlayer
          ref={videoRef}
          className='video'
          url={videoUrl}
          width='177.77777778vh'
          height={videoHeight}
          playing={isPlayVideo}
          loop={true}
          muted={true}
          onReady={handleVideoReady}
          onPause={onPause}
        />
      </div>
    </header>
  );
}


const styles = StyleSheet.create({
});

export default HpTopHead;
