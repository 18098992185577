import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { routeContact } from '../../../style/css/CmnStyle';
import { hpContext } from '../../hp/HPContext';


function SeoHeadTag() {
  const { t } = useTranslation();
  // 現在のパス
  const location = useLocation();
  const currentPath = location.pathname;

  const langCode = useContext(hpContext).langCode;

  const [title, setTitle] = useState(t('seo.main.title'));
  const [discription, setDiscription] = useState(t('seo.main.discription'));
  const [keywords, setKeyowrds] = useState(t('seo.main.keywords'));


  // 遷移ごとのSEO内容変更
  useEffect(() => {
    // 非同期にしないと反映されない
    setTimeout(() => {
      // デフォルト
      let newTitle = t('seo.main.title');
      let newDiscription = t('seo.main.discription');
      let newKeywords = t('seo.main.keywords');

      switch(currentPath) {
        case routeContact:
          // お問い合わせ
          newTitle = t('seo.contact.title');
          newDiscription = t('seo.contact.discription');
          newKeywords = t('seo.contact.keywords');
          break;
      }

      setTitle(newTitle);
      setDiscription(newDiscription);
      setKeyowrds(newKeywords);
    }, 0)
  }, [langCode, currentPath])


  return (
    <Helmet>
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={discription} />

      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={discription} />
      <meta property="og:url" content="https://skaters.cloud/" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={discription} />
    </Helmet>
  );
}

export default SeoHeadTag;