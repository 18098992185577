import React, { useState, useEffect, useContext } from 'react'

import { hpContext } from '../../HPContext';
import HpTopHead from './head/HpTopHead';
import HpContentPoint from './point/HpContentPoint';
import HpContentFunction from './functions/HpContentFunction';
import HpContentHowto from './howto/HpContentHowto';
import HpContentFeel from './feel/HpContentFeel';
import HpContentDownload from './download/HpContentDownload';


function Top() {
  const ReactGA = useContext(hpContext).ReactGA;

  useEffect(() => {
    // ログ：ページ
    ReactGA.event("pageTop");
  }, [])

  return (
    <div id="container">

      <HpTopHead />

      <HpContentPoint />
      <HpContentFunction />
      <HpContentHowto />
      <HpContentFeel />
      <HpContentDownload />

    </div> 
  );
}

export default Top;
