import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native';
import { TbSkateboard } from "react-icons/tb";
import { useTranslation } from 'react-i18next';

import { fontColorWhite, contentWrapColor } from '../../../../../style/css/CmnStyle';
import { hpContext } from '../../../HPContext';

function HpContentHowto(props) {
  const { t } = useTranslation();
  const isWide = useContext(hpContext).isWide;

  const windowDimensions = useContext(hpContext).windowDimensions;
  const windowWidth = windowDimensions.width;

  const descriptions = [
    'top.howto.discription.line1',
    'top.howto.discription.line2',
    'top.howto.discription.line3',
    'top.howto.discription.line4',
    'top.howto.discription.line5',
    'top.howto.discription.line6',
    'top.howto.discription.line7',
  ];

  return (
    <View id='howto' style={isWide? styles.containerWide : styles.container}>
    <View style={isWide? styles.mainWrapWide : styles.mainWrap}>

      {/* 使い方 */}
      <Text style={isWide? styles.titleWide : styles.title}>{t('top.howto.title')}</Text>
      <View
        style={[
          styles.underline,
          {marginHorizontal: isWide? windowWidth*0.4 : windowWidth*0.2},
          {marginBottom: isWide? 30 : 0},
        ]}
      ></View>
      

      <View style={styles.howtoRawOverWrap}>
      <View style={styles.howtoRawWrap}>
    
        {descriptions.map((desc, index) => (
          <View style={styles.howtoRaw} key={index}>
            <TbSkateboard size={isWide ? '2.5em' : '1.8em'} color={fontColorWhite} style={styles.howtoIcon} />
            <Text
              style={[
                styles.howtoTxt,
                { fontSize: isWide ? '1.5em' : '1em' },
              ]}
            >
              {t(desc)}
            </Text>
          </View>
        ))}
        
      </View>
      </View>

    </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: contentWrapColor,
    transform: 'skewY(-10deg)',
    paddingVertical: 50,
    marginBottom: 150,
  },
  mainWrap: {
    transform: 'skewY(10deg)',
  },

  containerWide: {
    backgroundColor: contentWrapColor,
    transform: 'skewY(-8deg)',
    paddingVertical: 50,
    marginBottom: 250,
  },
  mainWrapWide: {
    transform: 'skewY(8deg)',
  },

  title: {
    color: fontColorWhite,
    fontWeight: 'bold',
    fontSize: '1.7em',
    alignSelf: 'center',
  },
  titleWide: {
    color: fontColorWhite,
    fontWeight: 'bold',
    fontSize: '2.5em',
    alignSelf: 'center',
  },
  underline: {
    height: 2,
    backgroundColor: fontColorWhite,
    marginTop: 2,
  },

  howtoRawOverWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  howtoRawWrap: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  howtoRaw: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 15,
  },
  howtoIcon: {
    marginRight: 15,
    marginLeft: 20,
  },
  howtoTxt: {
    flex: 1,
    color: fontColorWhite,
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 4,
    marginRight: 10,
  },
});

export default HpContentHowto;
