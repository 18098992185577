import React, { useContext, useEffect, useState } from 'react'
import { View, Image, Text, TouchableOpacity, Pressable, StyleSheet } from 'react-native';
import { HashLink } from 'react-router-hash-link';
import { FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  blackColor, fontColorWhite,
  routeTop,
  routeTermOfService,
  routePrivacyPolicy,
  routeTokutei,
  routeContact
} from '../../../style/css/CmnStyle';
import { hpContext } from '../HPContext';


function HpFooter() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const langCode = useContext(hpContext).langCode;
  const isWide = useContext(hpContext).isWide;
  const windowDimensions = useContext(hpContext).windowDimensions;

  const windowWidth = windowDimensions.width;

  const onPressTwitter = () => {
    window.open('https://twitter.com/Skaters_app', '_blank');
    //window.open('twitter://user?screen_name=Skaters_app', '_blank');
  }

  const onPressInstagram = () => {
    if(langCode == 'ja') {
      // 日本語のインスタ
      window.open('https://www.instagram.com/skatersapp/', '_blank');
    } else {
      // 英語のインスタ
      window.open('https://www.instagram.com/skatersapp_en/', '_blank');
    }
  }

  return (
    <footer style={styles.container}>
      <View style={[
        styles.container,
        {paddingHorizontal: windowWidth * 0.1},
      ]}>

        <TouchableOpacity
          style={styles.logoWrap}
          onPress={() => {
            navigate(routeTop);
            window.scrollTo({top: 0});
          }}
        >
          <Image
            defaultSource={'./assets/img/logo.png'}
            style={isWide? styles.logoImgWide : styles.logoImg}
          />
          <Text style={styles.title}>Skaters</Text>
        </TouchableOpacity>


        <View style={isWide? styles.linkWrapWide : styles.linkWrap}>

          <View style={{marginRight: windowWidth*0.08}}>
            <HashLink
              to={routeTop + '#functions'}
              style={styles.hashLink}
            >
              {/* Skatersとは */}
              <Text style={styles.linkTxt}>{t('top.about.title')}</Text>
            </HashLink>

            <HashLink
              to={routeTop + '#howto'}
              style={styles.hashLink}
            >
              {/* 使い方 */}
              <Text style={styles.linkTxt}>{t('top.howto.title')}</Text>
            </HashLink>

            <HashLink
              to={routeTop + '#download'}
              style={styles.hashLink}
            >
              {/* ダウンロード */}
              <Text style={styles.linkTxt}>{t('download.title')}</Text>
            </HashLink>
          </View>

          <View style={{marginRight: windowWidth*0.08}}>
            <TouchableOpacity onPress={() => {
              navigate(routeTokutei);
              window.scrollTo({top: 0});
            }}>
              {/* 特定商取引法に基づく表記 */}
              <Text style={styles.linkTxt}>{t('tokusyou.title')}</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => {
              navigate(routeTermOfService);
              window.scrollTo({top: 0});
            }}>
              {/* 利用規約 */}
              <Text style={styles.linkTxt}>{t('kiyaku.title')}</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => {
              navigate(routePrivacyPolicy);
              window.scrollTo({top: 0});
            }}>
              {/* プライバシーポリシー */}
              <Text style={styles.linkTxt}>{t('privacy.title')}</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => {
              navigate(routeContact);
              window.scrollTo({top: 0});
            }}>
              {/* お問い合わせ */}
              <Text style={styles.linkTxt}>{t('contact.title')}</Text>
            </TouchableOpacity>
          </View>

          <View style={{marginRight: windowWidth*0.08}}>
            <Text style={isWide? styles.snsTitleWide : styles.snsTitle}>Follow</Text>
            <Pressable style={styles.snsLinkWrap} accessibilityRole="link" onPress={onPressTwitter}>
              <FaXTwitter size={18} color={fontColorWhite} />
              <Text style={[styles.linkTxt, {marginLeft: 5}]}>X(Twitter)</Text>
            </Pressable>

            <Pressable style={styles.snsLinkWrap} accessibilityRole="link" onPress={onPressInstagram}>
              <FaInstagram size={18} color={fontColorWhite} />
              <Text style={[styles.linkTxt, {marginLeft: 5}]}>Instagram</Text>
            </Pressable>
          </View>

        </View>

      </View>
    </footer>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    backgroundColor: blackColor,
    paddingVertical: 50,
    flexShrink: 0,
  },

  logoWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoImg: {
    width: '12vw',
    height: '12vw',
    borderRadius: 10,
  },
  logoImgWide: {
    width: '6vw',
    height: '6vw',
    borderRadius: 10,
  },
  title: {
    color: fontColorWhite,
    fontSize: '1.3em',
    fontWeight: 'bold',
  },

  linkWrap: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  linkWrapWide: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 10,
  },

  hashLink: {
    display: 'flex',
    textDecorationColor: 'transparent',
  },
  linkTxt: {
    color: fontColorWhite,
    fontSize: '0.8em',
    marginVertical: 10,
  },

  snsLinkWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  snsTitleWide: {
    color: fontColorWhite,
    fontSize: '0.9em',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  snsTitle: {
    color: fontColorWhite,
    fontSize: '0.9em',
    fontWeight: 'bold',
    marginTop: 25,
    marginBottom: 5,
  },
})

export default HpFooter;
