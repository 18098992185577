import React, { useState, useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import useMedia from "use-media";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../language/i18n'

import '../../../src/style/css/Hp.css';
import { hpContext } from './HPContext';
import SeoHeadTag from '../common/seo/SeoHeadTag';
import HpHeader from "./header/HpHeader";
import HpFooter from './footer/HpFooter';
import ChangeLanguage from '../common/language/ChangeLanguage';

import {
  routeTop, routeTopJa, routeTopPt, routeTopEs,
  routeTermOfService, routePrivacyPolicy, routeTokutei,
  routeContactPrepare, routeContact, routeContactDone,
} from '../../style/css/CmnStyle';
import Loading from '../loading/Loading';
import Top from './routes/top/Top';
import TermOfService from './routes/term_of_service/TermOfService';
import PrivacyPolicy from './routes/privacy_policy/PrivacyPolicy';
import Tokutei from './routes/tokutei/Tokutei';
import ContactPrepare from './routes/contact/ContactPrepare';
import Contact from './routes/contact/Contact';
import ContactDone from './routes/contact/ContactDone';
import Error404 from './routes/err/Error404';


// Analytics
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);


// 画面サイズ取得
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
} 


function Hp() {
  // ヘッダーの高さ
  const scrollHeadHeight = '8vh';
  // PC/SP判定
  const isWide = useMedia('(min-width: 780px)');
  // 画面サイズ
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  // 文字サイズ調整(ポルトガル語・スペイン語の場合小さくする)
  const [isTxtSmall, setIsTxtSmall] = useState(false);

  // 現在のパス
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  // 言語設定(en/ja)
  const [langCode, setLangCode] = useState();
  const { t, i18n } = useTranslation();

  const [isLangLoading, setIsLangLoading] = useState(true);


  // iOSダウンロードボタンがタップされたとき
  function onClickIosDownload() {
    // ログ：イベント
    ReactGA.event("eventTapIosDownload");
  }
  // Androidダウンロードボタンがタップされたとき
  function onClickAndroidDownload() {
    // ログ：イベント
    ReactGA.event("eventTapAndroidDownload");
  }

  const contextValue = {
    ReactGA: ReactGA,
    scrollHeadHeight: scrollHeadHeight,
    isWide: isWide,
    windowDimensions: windowDimensions,
    langCode: langCode,
    setLangCode: setLangCode,
    isTxtSmall: isTxtSmall,
    iosLink: 'https://apps.apple.com/app/skaters-tool/id6450068697?itsct=apps_box_link&itscg=30200',
    androidLink: 'https://play.google.com/store/apps/details?id=com.skaters.app',
    onClickIosDownload: onClickIosDownload,
    onClickAndroidDownload: onClickAndroidDownload,
  }


  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);


  // 初回言語設定
  useEffect(() => {
    (async() => {
      if(langCode == null) {
        try {
          // ローディング
          setIsLangLoading(true);

          const response = await fetch("https://ipapi.co/json/");
          const data = await response.json();

          if(data && data.languages) {
            if(data.languages.includes('en')) {
              // 英語の場合
              setLangCode('en');
            } else if(data.languages.includes('ja')) {
              // 日本の場合
              setLangCode('ja');
            } else if(data.languages.includes('pt')) {
              // ポルトガルの場合
              setLangCode('pt');
            } else if(data.languages.includes('es')) {
              // スペイン語の場合
              setLangCode('es');
            } else {
              // デフォルト：英語にする
              setLangCode('en');
            }
          } else {
            // デフォルト：英語にする
            setLangCode('en');
          }

        } catch(err) {
          // デフォルト：英語にする
          setLangCode('en');
        } finally {
          // ローディング終了
          setIsLangLoading(false);
        }
      } else {
        // 文字サイズ:ポルトガル語・スペイン語の場合は文字を小さく
        setIsTxtSmall(langCode=='pt' || langCode=='es');
      }
    })()
  }, [langCode])


  // 表示言語変更
  useEffect(() => {
    switch(langCode) {
      case 'en':
        // 英語
        i18n.changeLanguage('en');
        // 他トップの場合は英語URL
        if(
          currentPath == routeTopJa ||
          currentPath == routeTopPt ||
          currentPath == routeTopEs
        ) {
          navigate(routeTop);
        }
        break;
      case 'ja':
        // 日本語
        i18n.changeLanguage('ja');
        // 他トップの場合は日本語URL
        if(
          currentPath == routeTop ||
          currentPath == routeTopPt ||
          currentPath == routeTopEs
        ) {
          navigate(routeTopJa);
        }
        break;
      case 'pt':
        // ポルトガル語
        i18n.changeLanguage('pt');
        // 他トップの場合はポルトガル語URL
        if(
          currentPath == routeTop ||
          currentPath == routeTopJa ||
          currentPath == routeTopEs
        ) {
          navigate(routeTopPt);
        }
        break;
      case 'es':
        // スペイン語
        i18n.changeLanguage('es');
        // 他トップの場合はスペイン語URL
        if(
          currentPath == routeTop ||
          currentPath == routeTopJa ||
          currentPath == routeTopPt
        ) {
          navigate(routeTopEs);
        }
        break;
      default:
        // 英語(デフォルト)
        i18n.changeLanguage('en');
        // 他トップの場合は英語URL
        if(
          currentPath == routeTopJa ||
          currentPath == routeTopPt ||
          currentPath == routeTopEs
        ) {
          navigate(routeTop);
        }
    }
  }, [langCode, currentPath])



  return (
    <div className="App">
      <hpContext.Provider value={contextValue}>

        {/* SEO(headタグ内の多言語) */}
        <SeoHeadTag />

        {/* 言語ローディング */}
        {isLangLoading?
          <Loading />
        :
          <>
            <HpHeader />

            <View style={styles.container}>
              <Routes>
                <Route path={routeTop} element={<Top />} />
                <Route path={routeTopJa} element={<Top />} />
                <Route path={routeTopPt} element={<Top />} />
                <Route path={routeTopEs} element={<Top />} />
                <Route path={routeTermOfService} element={<TermOfService />} />
                <Route path={routePrivacyPolicy} element={<PrivacyPolicy />} />
                <Route path={routeTokutei} element={<Tokutei />} />
                {/* // お問い合わせの準備:GoogleFormと連携するID取得するため
                <Route path={routeContactPrepare} element={<ContactPrepare />} />
                */}
                <Route path={routeContact} element={<Contact />} />
                <Route path={routeContactDone} element={<ContactDone />} />
                <Route path="*" element={<Error404 />} />
              </Routes>
            </View>

            <HpFooter />

            <ChangeLanguage />
          </>
        }

      </hpContext.Provider>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1, // フッターを最下部にするため
  },
});

export default Hp;
