import React, { useContext, useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { hpContext } from '../../../HPContext';
import HpPointHowto from './howto/HpPointHowto';
import HpPointCompare from './compare/HpPointCompare';
import HpPointAnalytics from './analytics/HpPointAnalytics';


function HpContentPoint(props) {
  const { t } = useTranslation();
  const isWide = useContext(hpContext).isWide;
  const langCode = useContext(hpContext).langCode;

  const windowDimensions = useContext(hpContext).windowDimensions;
  const windowWidth = windowDimensions.width;
  const windowHeight = windowDimensions.height;

  const [pointHeight, setPointHeight] = useState(0);
  const [titleSize, setTitleSize] = useState(isWide? '3rem':'2rem');
  const [contentSize, setContentSize] = useState(isWide? '1.5rem':'1rem');


  useEffect(() => {
    if(windowHeight) {
      setPointHeight(
        isWide?
        windowHeight * 0.3:
        windowHeight * 0.4
      );
    }

    // 文字サイズ
    let newTitleSize = isWide? '3rem':'2rem';
    let newContentSize = isWide? '1.5rem':'1rem';
    switch(langCode) {
      case 'en':
      case 'es':
      case 'pt':
        newTitleSize = isWide? '3rem':'1.5rem';
        newContentSize = isWide? '1.5rem':'0.8rem';
        break
    }
    setTitleSize(newTitleSize);
    setContentSize(newContentSize);
  }, [windowHeight, isWide, langCode])


  const styles = StyleSheet.create({
    container: {
      width: windowWidth,
      backgroundColor: 'black',
      padding: isWide? 20 : 10,
    },
  });

  return (
    <View style={styles.container}>

      <HpPointHowto
        pointHeight={pointHeight}
        titleSize={titleSize}
        contentSize={contentSize}
      />

      <HpPointCompare
        pointHeight={pointHeight}
        titleSize={titleSize}
        contentSize={contentSize}
      />

      <HpPointAnalytics
        pointHeight={pointHeight}
        titleSize={titleSize}
        contentSize={contentSize}
      />

    </View>
  );
};

  

export default HpContentPoint;