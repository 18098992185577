import React, { useState, useContext, useEffect, useRef } from 'react';

// i18nextでは改行できない：改行したコンポーネント
function TransTextNextLine(props) {
  const text = props.text;

  return (
    <>
      {text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  );
}


export default TransTextNextLine;