import React, { useEffect, useContext } from 'react'
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../../../style/css/rule.css'
import { routeContact, routePrivacyPolicy } from '../../../../style/css/CmnStyle';
import { hpContext } from '../../HPContext'


// 特定商取引法に基づく表記
function Tokutei() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ReactGA = useContext(hpContext).ReactGA;

  const scrollHeadHeight = useContext(hpContext).scrollHeadHeight;
  const isWide = useContext(hpContext).isWide;

  useEffect(() => {
    // ログ：ページ
    ReactGA.event("pageTokuteisyou");
  }, [])

  return (
    <View style={{marginTop: scrollHeadHeight}}>
      <View style={isWide? styles.containerWide : styles.container}>

        {/* 特定商取引法に基づく表記 */}
        <Text style={styles.title}>{t('tokusyou.title')}</Text>

        <View style={styles.contentWrap}>

          <View style={styles.contentRaw}>
            {/* サービス代表者名 */}
            <Text style={styles.label}>{t('tokusyou.ceo.label')}</Text>
            {/* 古賀 太基 */}
            <Text style={isWide? styles.textWide : styles.text}>{t('tokusyou.ceo.content')}</Text>
          </View>

          <View style={styles.contentRaw}>
            {/* 所在地 */}
            <Text style={styles.label}>{t('tokusyou.address.label')}</Text>
            {/*
              〒333-0815{'\n'}
              埼玉県川口市北原台3-10-17
            */}
            <Text style={isWide? styles.textWide : styles.text}>{t('tokusyou.address.content')}</Text>
          </View>

          <View style={styles.contentRaw}>
            {/* 電話番号 */}
            <Text style={styles.label}>{t('tokusyou.tel.label')}</Text>
            {/* 048-296-9523 */}
            <Text style={isWide? styles.textWide : styles.text}>{t('tokusyou.tel.content')}</Text>
          </View>

          <View style={styles.contentRaw}>
            {/* 支払い方法 */}
            <Text style={styles.label}>{t('tokusyou.pay.label')}</Text>
            {/* クレジットカード（VISA/MasterCard/American Express/Diners Club）でのお支払いが可能です。 */}
            <Text style={isWide? styles.textWide : styles.text}>{t('tokusyou.pay.content')}</Text>
          </View>

          <View style={styles.contentRaw}>
            {/* 支払い時期 */}
            <Text style={styles.label}>{t('tokusyou.payTerm.label')}</Text>
            {/* カード決済が行われたタイミングで課金が発生いたします。 */}
            <Text style={isWide? styles.textWide : styles.text}>{t('tokusyou.payTerm.content')}</Text>
          </View>

          <View style={styles.contentRaw}>
            {/* 寄付の取り消し・返金について */}
            <Text style={styles.label}>{t('tokusyou.donateCancel.label')}</Text>
            {/* 寄付後のキャンセルや返金は原則として受け付けておりません。 */}
            <Text style={isWide? styles.textWide : styles.text}>{t('tokusyou.donateCancel.content')}</Text>
          </View>

          <View style={styles.contentRaw}>
            {/* 寄付の目的 */}
            <Text style={styles.label}>{t('tokusyou.donateAim.label')}</Text>
            {/* 運営費や開発費の補填、サービスの維持や改善、新機能の追加に利用させていただきます。 */}
            <Text style={isWide? styles.textWide : styles.text}>{t('tokusyou.donateAim.content')}</Text>
          </View>

          <View style={styles.contentRaw}>
            {/* 個人情報の取扱いについて */}
            <Text style={styles.label}>{t('tokusyou.personalInfo.label')}</Text>
            {/* 個人情報の取扱いに関しては、プライバシーポリシーをご参照ください。 */}
            <Text style={isWide? styles.textWide : styles.text}>
              {t('tokusyou.personalInfo.content.line1')}
              <Pressable accessibilityRole="link" onPress={() => {
                navigate(routePrivacyPolicy);
                window.scrollTo({top: 0});
              }}>
                <Text style={{color: 'blue'}}>{t('privacy.title')}</Text>
              </Pressable>
              {t('tokusyou.personalInfo.content.line2')}
            </Text>
          </View>

          <View style={styles.contentRaw}>
            {/* お問い合わせ窓口 */}
            <Text style={styles.label}>{t('tokusyou.contact.label')}</Text>
            {/* お問い合わせはこちら */}
            <Text style={isWide? styles.textWide : styles.text}>
              {t('tokusyou.contact.content')}
              <Pressable accessibilityRole="link" onPress={() => {
                navigate(routeContact);
                window.scrollTo({top: 0});
              }}>
                <Text style={{color: 'blue'}}>{t('common.here')}</Text>
              </Pressable>
            </Text>
          </View>

        </View>

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: '10%',
    marginHorizontal: '5%',
  },
  containerWide: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: '5%',
    marginHorizontal: '25%',
  },

  title: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: 30,
  },

  contentWrap: {
    width: '100%',
  },

  contentRaw: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginVertical: 15,
  },

  label: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    marginVertical: 3,
  },

  text: {
    fontSize: '1.1em',
    marginLeft: 20,
  },
  textWide: {
    fontSize: '1.2em',
    marginLeft: 20,
  },
});

export default Tokutei;