import React, { useState, useContext, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native-web';
import { AiOutlineGlobal, AiFillCaretDown, AiOutlineCheck } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  fontColorWhite, blackColor,
  routeTop, routeTopJa, routeTopPt, routeTopEs,
} from '../../../style/css/CmnStyle';
import { hpContext } from '../../hp/HPContext';

function ChangeLanguage(props) {
  const { t, i18n } = useTranslation();

  const isWide = useContext(hpContext).isWide;
  const langCode = useContext(hpContext).langCode;
  const setLangCode = useContext(hpContext).setLangCode;

  // 現在のパス
  const location = useLocation();
  const currentPath = location.pathname;

  // SP：少しスクロールしないと表示しない
  const [isDisp, setIsDisp] = useState(true);
  const [isOpen, setIsOpen] = useState(false);


  // スクロール
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;

    if(isWide) {
      // PCは常に表示
      setIsDisp(true);
    } else {
      if(
        currentPath == routeTop ||
        currentPath == routeTopJa ||
        currentPath == routeTopPt ||
        currentPath == routeTopEs
      ) {
        // SPで各言語のトップページの場合は少しスクロールして表示
        setIsDisp(scrollY > windowHeight/3);
      } else {
        setIsDisp(true);
      }
    }
  }


  useEffect(() => {
    // スクロール処理
    handleScroll();
    window.addEventListener('scroll', handleScroll);
  }, [])


  const onPressLanguage = () => {
    setIsOpen(!isOpen);
  }

  // 英語
  const onPressEn = () => {
    setLangCode('en');
    setIsOpen(false);
  }
  // ポルトガル語
  const onPressPt = () => {
    setLangCode('pt');
    setIsOpen(false);
  }
  // スペイン語
  const onPressEs = () => {
    setLangCode('es');
    setIsOpen(false);
  }
  // 日本語
  const onPressJa = () => {
    setLangCode('ja');
    setIsOpen(false);
  }



  const styles = StyleSheet.create({
    container: {
      position: 'fixed',
      bottom: isWide? 30 : 10,
      right: isWide? 30 : 15,
      zIndex: 10,
      backgroundColor: blackColor,
      borderColor: fontColorWhite,
      borderWidth: 1,
      borderRadius: 10,
    },

    languageBtn: {
      flexDirection: 'row',
      paddingHorizontal: isWide? 20 : 10,
      paddingVertical: isWide? 7 : 5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    languageTxt: {
      color: fontColorWhite,
      fontSize: isWide? '1rem' : '0.8rem',
      fontWeight: 'bold',
      marginHorizontal: 5,
    },

    btn: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#CCCCCC',
      paddingHorizontal: isWide? 20 : 10,
      paddingVertical: isWide? 7 : 5,
      borderColor: blackColor,
      borderTopWidth: 1,
    },
    btnCheck: {
      flex: 1,
    },
    txt: {
      flex: 5,
      textAlign: 'center',
      color: blackColor,
      fontSize: isWide? '1rem' : '0.8rem',
      fontWeight: 'bold',
    },
  });


  return (
    <>
    {isDisp &&
      <View style={styles.container}>

        <TouchableOpacity
          style={[
            styles.languageBtn,
          ]}
          onPress={onPressLanguage}
        >
          <AiOutlineGlobal
            size={isWide? '1.5em' : '1em'}
            color={fontColorWhite}
          />
          <Text style={styles.languageTxt}>Lang</Text>
          <AiFillCaretDown
            size={isWide? '1em' : '0.7em'}
            color={fontColorWhite}
          />
        </TouchableOpacity>



        {isOpen &&
          <>
            {/* 英語 */}
            <TouchableOpacity
              style={styles.btn}
              onPress={onPressEn}
            >
              {langCode=='en' &&
                <AiOutlineCheck
                  size={isWide? '1em' : '0.7em'}
                  color={blackColor}
                  style={styles.btnCheck}
                />
              }
              <Text style={styles.txt}>English</Text>
            </TouchableOpacity>

            {/* ポルトガル語 */}
            <TouchableOpacity
              style={styles.btn}
              onPress={onPressPt}
            >
              {langCode=='pt' &&
                <AiOutlineCheck
                  size={isWide? '1em' : '0.7em'}
                  color={blackColor}
                  style={styles.btnCheck}
                />
              }
              <Text style={styles.txt}>Português</Text>
            </TouchableOpacity>

            {/* スペイン語 */}
            <TouchableOpacity
              style={styles.btn}
              onPress={onPressEs}
            >
              {langCode=='es' &&
                <AiOutlineCheck
                  size={isWide? '1em' : '0.7em'}
                  color={blackColor}
                  style={styles.btnCheck}
                />
              }
              <Text style={styles.txt}>Español</Text>
            </TouchableOpacity>

            {/* 日本語 */}
            <TouchableOpacity
              style={[
                styles.btn,
                {borderBottomLeftRadius: 10},
                {borderBottomRightRadius: 10},
              ]}
              onPress={onPressJa}
            >
              {langCode=='ja' &&
                <AiOutlineCheck
                  size={isWide? '1em' : '0.7em'}
                  color={blackColor}
                  style={styles.btnCheck}
                />
              }
              <Text style={styles.txt}>日本語</Text>
            </TouchableOpacity>
          </>
        }
      </View>
    }
    </>
  );
}


export default ChangeLanguage;