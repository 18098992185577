import React, { useContext } from 'react'
import { StyleSheet, Image, Text, View } from 'react-native';
import TransTextNextLine from '../../../../common/language/TransTextNextLine';
import { useTranslation } from 'react-i18next';

import { blackColor } from '../../../../../style/css/CmnStyle';
import { hpContext } from '../../../HPContext';

function HpContentDownload(props) {
  const { t } = useTranslation();
  const isWide = useContext(hpContext).isWide;
  const iosLink = useContext(hpContext).iosLink;
  const androidLink = useContext(hpContext).androidLink;

  const windowDimensions = useContext(hpContext).windowDimensions;
  const windowWidth = windowDimensions.width;
  const windowHeight = windowDimensions.width;

  const onClickIosDownload = useContext(hpContext).onClickIosDownload;
  const onClickAndroidDownload = useContext(hpContext).onClickAndroidDownload;


  return (
    <View id='download' style={isWide? styles.containerWide : styles.container}>

      <View style={isWide? styles.screenWrapWide : styles.screenWrap}>
        <Image
          defaultSource={t('img.main')}
          resizeMode='contain'
          style={[
            {width: isWide? windowWidth*0.35 : windowWidth*0.95},
            {height: isWide? windowHeight*0.35 : windowHeight*0.95},
          ]}
        />
      </View>


      <View style={isWide? styles.descriptionOverWrapWide : styles.descriptionOverWrap}>
      <View style={styles.descriptionWrap}>
        <View style={styles.logoWrap}>
          <Image
            defaultSource={'./assets/img/logo.png'}
            style={isWide? styles.logoImgWide : styles.logoImg}
          />
          <Text style={isWide? styles.nameWide : styles.name}>Skaters</Text>
        </View>

        {/*
          トリックの瞬間を見逃さない{"\n"}
          スケーター専用アプリ
        */}
        <Text style={isWide? styles.subtitleWide : styles.subtitle}>
          <TransTextNextLine text={t('common.catch')} />
        </Text>

        <View style={isWide? styles.downloadBtnWrapWide : styles.downloadBtnWrap}>
          <a href={iosLink} onClick={onClickIosDownload} rel="noopener" target="_blank">
            <Image
              defaultSource={t('download.iosBtnPath')}
              resizeMode='contain'
              style={isWide? styles.downloadBtnAppleWide : styles.downloadBtnApple}
            />
          </a>
          <a href={androidLink} onClick={onClickAndroidDownload} rel="noopener" target="_blank">
            <Image
              defaultSource={t('download.androidBtnPath')}
              resizeMode='contain'
              style={isWide? styles.downloadBtnGoogleWide : styles.downloadBtnGoogle}
            />
          </a>
        </View>

      </View>
      </View>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#E6E9EA',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 60,
    paddingBottom: 60,
  },
  containerWide: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#E6E9EA',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 60,
    paddingBottom: 60,
  },

  screenWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  screenWrapWide: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },

  descriptionOverWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  descriptionOverWrapWide: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  descriptionWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  logoWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
  },
  logoImg: {
    width: '13vw',
    height: '13vw',
    borderRadius: 10,
  },
  logoImgWide: {
    width: '5vw',
    height: '5vw',
    borderRadius: 10,
  },

  name: {
    color: blackColor,
    fontSize: '1.7em',
    fontWeight: 'bold',
    marginHorizontal: 5,
  },
  nameWide: {
    color: blackColor,
    fontSize: '2.8em',
    fontWeight: 'bold',
    marginHorizontal: 20,
  },

  subtitle: {
    color: blackColor,
    fontSize: '0.9em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 30,
  },
  subtitleWide: {
    color: blackColor,
    fontSize: '1.3em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 30,
  },

  downloadBtnWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  downloadBtnWrapWide: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  downloadBtnApple: {
    width: 206,
    height: 68,
    marginVertical: 7,
  },
  downloadBtnGoogle: {
    width: 236,
    height: 71,
    marginVertical: 7,
  },
  downloadBtnAppleWide: {
    width: 206,
    height: 70,
    marginVertical: 7,
  },
  downloadBtnGoogleWide: {
    width: 236,
    height: 81,
    marginVertical: 7,
  },
});

export default HpContentDownload;
