import React, { useState, useContext, useEffect } from 'react';
import { StyleSheet, Image, Text, ActivityIndicator, View } from 'react-native';

import { fontColorWhite, blackColor } from '../../style/css/CmnStyle';
import { hpContext } from '../hp/HPContext';


function Loading() {
  const isWide = useContext(hpContext).isWide;

  const [iconSize, setIconSize] = useState(0);
  const [titleSize, setTitleSize] = useState(0);

  useEffect(() => {
    // ロゴサイズ
    setIconSize(
      isWide? window.innerWidth*0.1 : window.innerWidth*0.3
    );
    // アプリ名
    setTitleSize(
      isWide? '3rem' : '2rem'
    );
  }, [isWide])


  return (
    <View
      style={[
        styles.container,
        {width: window.innerWidth},
        {height: window.innerHeight},
      ]}
    >

      <Image
        defaultSource={'./assets/img/logo.png'}
        resizeMode='contain'
        style={[
          {width: iconSize},
          {height: iconSize},
        ]}
      />

      <Text style={[styles.title, {fontSize: titleSize}]}>Skaters</Text>

      <ActivityIndicator
        color={fontColorWhite}
        size={'large'}
      />

    </View> 
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: blackColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: fontColorWhite,
    fontWeight: 'bold',
  },
});

export default Loading;